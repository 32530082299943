import Api from "@/services/Api";
import { jwtDecode, InvalidTokenError } from 'jwt-decode';
import moment from 'moment';

export const AuthService = {
    async login(username, password) {
        try {
            const response = await Api().post(`/auth/login/admin`, {
                email: username,
                password
            });
            const result = response.data;
            if (!result || (result.success !== undefined && !result.success)) {
                return false;
            }
            localStorage.setItem('userToken', result.data.userDataToken)
            return true;
        } catch (error) {
            console.error('login error: ' + error);
        }
    },
    logout() {
        localStorage.removeItem('userToken');
        return true;
    },
    validateAndGetUserFromJwtToken(token) {
        try {
            const tokenData = jwtDecode(token);
            if (tokenData) {
                return { ...tokenData };
            }
        } catch (e) {
            // Log out only unknown errors.
            if (e instanceof InvalidTokenError) {
                // Invalid token is an expected error when the token is invalid.
            } else {
                console.error('vAGUFJT error: ', e);
            }
        }
        return false;
    },
    isAuthenticated() {
        const token = localStorage.getItem('userToken');
        if (token) {
            const user = this.validateAndGetUserFromJwtToken(token);
            if (user) {
                const { exp } = user;
                const currentTime = moment();
                const timeDiff = moment.unix(exp).diff(currentTime);
                // token not expired
                if (timeDiff > 0) {
                    return true;
                }
            }
            // corrupted token.
        }

        localStorage.removeItem('userToken');
        return false;
    }
}
