import Login from '../views/Login/LoginView.vue'
import {createRouter, createWebHistory} from "vue-router";


const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/DashboardView.vue'),
  },
  {
    path: '/crash-report/:crashType/:crashReportId',
    name: 'crash-report.detail-view',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CrashReports/DetailView.vue'),
    props: true,
  },
  {
    path: '/crash-report/:crashType',
    name: 'crash-report.list-view',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CrashReports/ListView.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
