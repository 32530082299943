<template>
  <div class="root">
    <main class="text-center form-signin">
      <form @submit.prevent="handleSubmit">
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

        <p v-if="loginError" class="alert-danger">Unable to login</p>

        <div class="form-floating">
          <input v-model="username" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
          <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating">
          <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
          <label for="floatingPassword">Password</label>
        </div>

        <button :disabled="!isSubmitAllowed" class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
      </form>
    </main>
  </div>
</template>

<script setup>
import {ref, computed, onBeforeMount} from "vue";
import {AuthService} from "@/services/AuthService";
import {getValue} from "@/helpers/getValueOfReactiveVariable";
import {useRouter} from "vue-router";

onBeforeMount(() => {
  if (AuthService.isAuthenticated()) {
    return redirectToDashboard();
  }
})

const username = ref(null)
const password = ref(null)
const loginError = ref(false);

const validUserName = computed(() => (getValue(username) && getValue(username).length > 4));
const validPassword = computed(() => (getValue(password) && getValue(password).length > 4));
const isSubmitAllowed = computed(() => (getValue(validUserName) && getValue(validPassword)));

const handleSubmit = async () => {
  const success = await AuthService.login(getValue(username), getValue(password));
  if (success) {
    return redirectToDashboard();
  }
  loginError.value = true;
};

const router = useRouter();

const redirectToDashboard = () => {
  return router.push({
    name: 'dashboard'
  })
}
</script>

<style scoped>
.root {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
