import { createApp } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

const app = createApp(App);

app.use(router);
app.use(VueApexCharts);

app.config.devtools = true;

app.mount('#app');
