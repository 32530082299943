<template>
    <router-view :key="route.path"/>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style>
html,
body {
  height: 100%;
}
body {
  font-size: .875rem;
}
</style>
